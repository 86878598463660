export function TextDiv({textTitle, textSubtitle, colorTitle, colorSubtitle, widthSubtitle}) {
    return(
        <div id="textdivs_box">
            <div id="title_spanbox">
                <span className="span_title" style={{color: colorTitle}}>{textTitle}</span>
            </div>
            <div id="subtitle_spanbox">
                <span className="span_subtitle" style={{color: colorSubtitle, width:widthSubtitle}}>{textSubtitle}</span>
            </div>
        </div>
    );
    
}