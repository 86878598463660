import { LazyLoadImage } from "react-lazy-load-image-component";
import the_photo from '../../images/coroncine.webp';

export function WarehouseStock() {
    return(
        <div className="stock_body">
            <LazyLoadImage className="stock_images" src={the_photo} alt="the photo"/>
        </div>
        
    );
    
}