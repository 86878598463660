import { Exp } from "../laser_page_components/Exp";
import React, { useEffect, useState } from 'react';

import design_img from '../../images/production drawing.png';

export function PartsCommision () {

    const [isSmallScreen, setIsSmallScreen] = useState(false);
        useEffect(() => {
            // Function to check screen size
            const handleResize = () => {
                setIsSmallScreen(window.innerWidth <= 1000);
            };
    
            // Initial check
            handleResize();
    
            // Add event listener for resize
            window.addEventListener("resize", handleResize);
    
            // Cleanup
            return () => window.removeEventListener("resize", handleResize);
    }, []);

    return(
        <div className="commision">
            <div className={`txt_img_laser_exposition ${isSmallScreen ? "txt_top" : ""}`}>
                <Exp 
                    txtposition={isSmallScreen ? "top" : "left"}
                    textTitle="Project your Gear"
                    textSubtitle="Commission the gears you need! We start from a sample,
                     to the drawing to the final gear"
                    paragraphImg={design_img}
                    objFit="contain"
                    objPosition="center"
                />
            </div>
        </div>
    );
}