import '../../styles/LaserComponents.css'
import { TextDiv } from './TextDiv';

export function Exp({txtposition, textTitle, textSubtitle, paragraphImg, objFit, objPosition, colorTitle, backgroundImage}) {
    if(txtposition === "top" || txtposition === "left"){
        return(
            <>
            <div id="intro_text">
                <TextDiv 
                    textTitle = {textTitle}
                    textSubtitle = {textSubtitle}
                />
            </div>
            <div id="intro_img_box">
                <img className="intro_img" src={paragraphImg} alt='laser img' style={{objectFit: objFit, objectPosition: objPosition}}/>
            </div>
            </>
        );
    } else if (txtposition === "bottom" || txtposition === "right") {
        return(
            <>
            <div id="intro_img_box"><img className="intro_img" src={paragraphImg} alt='laser img' style={{objectFit: objFit, objectPosition: objPosition}}/></div>
            <div id="intro_text">
                <TextDiv 
                    textTitle = {textTitle}
                    textSubtitle = {textSubtitle}
                />
            </div>
            </>
        );
    } else if (txtposition === "over") {
        return(
            <div className='over'>
                <div id="over_img_box" style={{backgroundImage: backgroundImage}}><img className="intro_img" src={paragraphImg} alt='laser img' style={{objectFit: objFit, objectPosition: objPosition}}/></div>
                <div id="over_text">
                    <TextDiv 
                        textTitle = {textTitle}
                        textSubtitle = {textSubtitle}
                        colorTitle={colorTitle}
                        colorSubtitle="rgb(255, 255, 255)"
                        widthSubtitle='40%'
                    />
                </div>
            </div>
        );
    }
}