import { Route, Routes } from 'react-router-dom';
import './App.css';

import { Header } from './components/Header';
import { Contacts } from './pages/Contacts';
import { Home } from './pages/Home';
import { Products } from './pages/Products';
import { NotFound } from './pages/NotFound';
import { AboutUs } from './pages/AboutUs';
import { IndustrialMachines } from './pages/IndustrialMachines';
import { SpareParts } from './pages/SpareParts';


function App() {
  var wHeight = document.getElementsByClassName('App-content').offsetHeight;
  console.log(wHeight);
  

  return (
    <div className="App">
      <header className='App-header'>
        <Header />
      </header>
      <main className='App-content'>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path='/about_us' element={<AboutUs/>}/>
        <Route path='/products' element={<Products/>}/>
        <Route path='/contacts' element={<Contacts/>}/>
        <Route path='/industrial_machines' element={<IndustrialMachines/>}/>
        <Route path='/spare_parts' element={<SpareParts/>}/>
        <Route path='/1' element={<Home />}/>
        <Route path='*' element={<NotFound/>}/>
      </Routes>
      </main>
    </div>
  );
}

export default App;

