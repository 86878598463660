import photo_1 from '../images/mazak_presentation.webp';
import photo_2 from '../images/coroncine.webp';
import photo_3 from '../images/laser/laser lens front head.jpg';

export const photoRoll=[
    {
        text: 'We produce our products by using high-end precision machineries. All parts and gears produced in Italy',
        img: photo_1,
        type: 'presentation'
    },

    {
        text: 'Spare parts for many companies and types of tractors',
        img: photo_2,
        type: 'products'
    },

    {
        text: 'Laser markers for industrial use',
        img: photo_3,
        type: 'industrial_machines'
    }
]