import LazyImage from '../LazyImage';
import { productsHome } from "../../list/productsHome";

export function PartsTypeIntro() {
    return(
        <div className="intro_spare_parts">
            {productsHome.map ((item,index) => {
                while(item.mainSubject === "heavy machinery"){
                    return(
                        <div className="key_intro_spare_parts" key={index}>
                        {item.subjects.map ((subItem, subIndex) => {
                            return(
                                <div key={subIndex} className={`singolar_intro ${(subIndex %2 === 0) ? "" : "border_left"}`}>
                                    <LazyImage className="parts_intro_img" src={subItem.sb_image} alt={subItem.image_alt}/>
                                    <div className="parts_intro_title">{subItem.subject}</div>
                                    <div className="parts_button_container">
                                        <button className="button" style={{height: "40%"}}>Check the stock</button>
                                    </div>
                                </div>
                            );
                        })}
                        </div>
                    ); 
                }
                return "";
            })}
            
        </div>
    );
    
}