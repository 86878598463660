import '../styles/SpareParts.css';

import { Footer } from "../components/Footer";
import { PartsTypeIntro } from "../components/parts_page_components/parts_type_intro";
import { PartsCommision } from '../components/parts_page_components/parts_commision';
import { WarehouseStock } from '../components/parts_page_components/warehouse_stock';

export function SpareParts() {
    return(<>
    <div className="page_body">
        <div className="spare_parts_body">
            <PartsTypeIntro />
            <PartsCommision />
            <WarehouseStock />
        </div>
        <Footer/>
    </div>
    </>);
}