import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { productsHome } from '../list/productsHome.js';
import '../styles/ProductsHome.css';
import LazyImage from './LazyImage.js';

export function ProductsHome(){

    useEffect(() => {
        function checkVisibility() {
          var elements = document.querySelectorAll('.sb_fade-in_prod');
          for (var i = 0; i < elements.length; i++) {
            var windowHeight = window.innerHeight;
            var scrollY = window.scrollY || window.pageYOffset;
            var elementOffsetTop = elements[i].getBoundingClientRect().top + scrollY;
    
            if (scrollY + windowHeight > elementOffsetTop) {
              elements[i].classList.add('show_prod');
            }
          }
        }
    
        window.addEventListener('scroll', checkVisibility);
        window.addEventListener('resize', checkVisibility);
    
        checkVisibility();

        return () => {
          window.removeEventListener('scroll', checkVisibility);
          window.removeEventListener('resize', checkVisibility);
        };
    }, []);

    return(<>
    <div className='home_product_box'>
        {productsHome.map((item,index) => {
            return(<>
                <div key={index} className={`${item.mainSubject.includes('industrial') ? 'laser' : 'tractor'}_product`}>
                    <div className='sb_product_title'>{item.description}</div>
                    {item.subjects.map((subItem,subIndex) => {
                        return(
                        <div className='subject_box' key={subIndex}>
                            <div className='sbj'>{subItem.subject}</div>
                            <div className='upper_box'>
                                <div className='sbtitle'>{subItem.subtitle}</div>
                                <div className='box_sb_image'><LazyImage className='sb_image' src={subItem.sb_image} alt='parts'/></div>
                                <div className={`${item.mainSubject.includes('industrial') ? 'laser' : 'tractor'}_sb_button`}><Link className='button' to={subItem.link_to}>Look</Link></div>
                            </div>
                            <div className='sb_second_title'>{subItem.second_subtitle}</div>
                            <div className='sb_images_slider sb_fade-in_prod'>
                            {subItem.brands.map((brandItem,brandIndex) => {
                                return(
                                <>
                                <div className={`${subItem.subject.includes('laser') ? 'laser' : 'machines'}_sb_photo_box`} key={brandIndex}>
                                    <LazyImage className='sb_brand' src={brandItem} alt={brandItem}/>
                                </div>
                                </>
                                );
                            })}</div>
                        </div>
                        );
                    })}
                </div>
            </>)
        })}
    </div>
    </>);
}