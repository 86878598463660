import '../styles/IndustrialMachines.css';

import { Footer } from '../components/Footer.js';
import { ImgTextIntro } from '../components/laser_page_components/ImgTextIntro.js';

export function IndustrialMachines() {
    return(
        <>
        <div className="page_body">
            <div className='industrial_machines_body'>
                <ImgTextIntro/>
            </div>
            <Footer />
        </div>
        </>
    );
}