import '../../styles/LaserComponents.css'
import intro_laser_img from '../../images/laser/laser marker intro lesn.png'
import second_laser_img from '../../images/laser/laser marker machine.png';
import medium_laser_open from '../../images/laser/medium laser opened cabin.png';
import adjustable_height from '../../images/laser/laser marker open right view.jpg';
import laser_marker_computer from '../../images/laser/laser marker computer.jpg';
import turnon_buttons from '../../images/laser/laser marker lateral commands.jpg';

import welder_intro from '../../images/laser/laser welder machine.png';
import welder_gun_rope from '../../images/laser/welder right view.jpg';
import welder_commands from '../../images/laser/welder commands.png';
import welder_fan from '../../images/laser/welder fan.png';
import welder_gun from '../../images/laser/welder gun.png';
import { Exp } from './Exp';
import React, { useEffect, useState } from 'react';

export function ImgTextIntro() {


    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        // Function to check screen size
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1000);
        };

        // Initial check
        handleResize();

        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return(<>
    <div id="intro_box">
        <div className="body_title" style={{backgroundColor: "black", color: "white", padding: "0"}}>Laser Engraver Machine</div>
        <div className='txt_img_laser_exposition'>
            <Exp 
                txtposition="over"
                textTitle="A Functional, Compact, Dismountable and Modifiable machine for all necessary uses"
                textSubtitle=""
                paragraphImg={medium_laser_open}
                objFit="contain"
                objPosition="center"
                colorTitle="orange"
            />
        </div>
        <div className={`txt_img_laser_exposition ${isSmallScreen ? "txt_top" : ""}`}>
            <Exp 
                txtposition={isSmallScreen ? "top" : "left"}
                textTitle="All In One"
                textSubtitle="The machine starts by having a great and useful compact size. It features the computer, the laser core CPU and the arm with the laser lens, all inside in one place"
                paragraphImg={second_laser_img}
                objFit="contain"
                objPosition="center"
            />
        </div>
        <div className={`txt_img_laser_exposition ${isSmallScreen ? "txt_top" : ""}`}>
            <Exp 
                txtposition={isSmallScreen ? "top" : "right"}
                textTitle="Engrave With Laser Precision"
                textSubtitle="Opening the door you see the Arm and the Head: the most important parts, as the focal point adjustments and the firing are made here"
                paragraphImg={intro_laser_img}
                objFit="scale-down"
                objPosition="left"
            />
        </div>
        <div className={`txt_img_laser_exposition ${isSmallScreen ? "txt_top" : ""}`}>
            <Exp 
                txtposition={isSmallScreen ? "top" : "left"}
                textTitle="Easy to Work With"
                textSubtitle="The adjustable height and the openable lateral doors, makes the machine easy operable with
                various items, of any height and lenght"
                paragraphImg={adjustable_height}
                objFit="contain"
                objPosition="center"
            />
        </div>
        <div className='txt_img_laser_exposition txt_top'>
            <Exp 
                txtposition={isSmallScreen ? "top" : "top"}
                textTitle="Computer and Sowftware"
                textSubtitle="The computer is assembled in the machine with is own software for engraving.
                Write, prepare and engrave the codes, QR codes, logos and actually all you want"
                paragraphImg={laser_marker_computer}
                objFit="contain"
                objPosition="center"
            />
        </div>
        <div className={`txt_img_laser_exposition ${isSmallScreen ? "txt_top" : ""}`}>
            <Exp 
                txtposition={isSmallScreen ? "top" : "left"}
                textTitle="Easy Commands"
                textSubtitle="Easy and safe control the machine form the laterl commands. Turn on the machine with the key,
                activate the laser light, move up and down the arm, turn on the lights, all without opening the safe door"
                paragraphImg={turnon_buttons}
                objFit="contain"
                objPosition="center"
            />
        </div>

        <div className="body_title" style={{backgroundColor: "orange", color: "black", textDecorationColor: "black"}}>Laser Welder Machine</div>
        <div className='txt_img_laser_exposition'>
            <Exp 
                txtposition="over"
                textTitle="Easy to Transport, Easy to Operate. A Great Welding Machine"
                textSubtitle=""
                paragraphImg={welder_intro}
                objFit="contain"
                objPosition="center"
                colorTitle="white"
                backgroundImage="linear-gradient( orange, rgb(255, 192, 76), white)"
            />
        </div>
        <div className={`txt_img_laser_exposition ${isSmallScreen ? "txt_top" : ""}`}>
            <Exp
                txtposition={isSmallScreen ? "top" : "left"}
                textTitle="Compact but Convenient"
                textSubtitle="The machine is easy to carry around thanks to its integrated wheels. The 3 to 5 meters head rope 
                makes the machine covenient to use in any place as can reach any spot"
                paragraphImg={welder_gun_rope}
                objFit="contain"
                objPosition="center"
            />
        </div>
        <div className={`txt_img_laser_exposition ${isSmallScreen ? "txt_top" : ""}`}>
            <Exp
                txtposition={isSmallScreen ? "top" : "bottom"}
                textTitle="Straightforward Commands"
                textSubtitle="The important commands: On/Off, Laser Fire activation and the Emergency Button, are in the perfect position to reach, esay to understand and use.
                Those features make the machine extremely safe"
                paragraphImg={welder_commands}
                objFit="contain"
                objPosition="center"
            />
        </div>
        <div className={`txt_img_laser_exposition ${isSmallScreen ? "txt_top" : ""}`}>
            <Exp
                txtposition={isSmallScreen ? "top" : "left"}
                textTitle="Efficency"
                textSubtitle="The machine has a cooler fan that provides stability to the Laser Diode. The laser can have
                a power of 1000W up to 3000W."
                paragraphImg={welder_fan}
                objFit="contain"
                objPosition="center"
            />
        </div>
        <div className={`txt_img_laser_exposition ${isSmallScreen ? "txt_top" : ""}`}>
            <Exp
                txtposition={isSmallScreen ? "top" : "right"}
                textTitle="Laser Head"
                textSubtitle="The laser head is lightweight, excellent grip for a secure handling, and impact resistance.
                The laser lens inside the laser head, can be changed quickly without disassembling"
                paragraphImg={welder_gun}
                objFit="contain"
                objPosition="center"
            />
        </div>
    </div>
    </>);
}