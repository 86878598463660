import { React } from 'react';
import { Footer } from "../components/Footer";
import "../styles/AboutUs.css";
import { story } from "../list/aboutUs";
import LazyImage from '../components/LazyImage';

export function AboutUs() {

    return(
        <>
        <div className="page_body">
            <div className="body_title">B.F. company story and work</div>
            <div className='story_display'>
                {story.map((item,index) =>{
                    return(
                        <>
                        <div className='regular'>
                          <div key={index} className={item.className}>
                              <div className={item.cn_img}><LazyImage className="img_story" src={item.story_img} alt="story" /></div>
                              <div className={item.cn_text}>{item.text}</div>
                          </div>
                        </div>
                        </>
                    );
                })}
            </div>
            <Footer />
        </div>
        </>
    );
    
}